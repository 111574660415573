import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ContentDrawer from "../../components/ContentDrawer";
import InputControl from "../../components/controls/InputControl";
import { API_COLORFINISHES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useTable from "../../hooks/useTable";
import FinishDetails from "./FinishDetails";
import FinishForm from "./FinishForm";

const columns = [
  { field: "colorFinishId", label: "ID", width: 1 },
  { field: "name", label: "Name", width: 3 },
  { field: "createdDate", label: "Created On Date", width: 3 },
  { field: "modifiedDate", label: "Modified On Date", width: 3 },
  {
    field: "action",
    label: "Actions",
    width: 2,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

function FinishesList() {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [contentDrawer, setContentDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_COLORFINISHES);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const inputs = Array.from(document.querySelectorAll(".searchInput input"));
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        var x;
        for (const { name, value } of inputs) {
          if (value) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
        }
        return items;
      },
    });
  };

  const [IsForm, setIsForm] = useState(false);
  const openInDrawer = (action, record = null) => {
    setSelectedRecord(record ? { ...record } : null);
    setIsForm(action === "add" || action === "edit" ? true : false);
    setSelectedIndex(action === "add" ? -1 : selectedIndex);
    setContentDrawer(true);
  };

  const addOrEdit = async (record, resetForm) => {
    setIsSaving(true);
    if (!record.colorFinishId) {
      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };
      const results = await ApiFetch(
        API_COLORFINISHES,
        requestOptions,
        setNotify,
        "Finish Added Successfully!"
      );

      if (results) {
        setRecords([...records, results]);
        setSelectedIndex(records.length);
        resetForm();
      }
    } else {
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };
      const results = await ApiFetch(
        `${API_COLORFINISHES}/${record.colorFinishId}`,
        requestOptions,
        setNotify,
        "Finish Updated Successfully!"
      );

      if (results) {
        const index = records.findIndex(
          (el) => el.colorFinishId === record.colorFinishId
        );
        records[index] = results;
        setSelectedRecord({ ...results });
      }
    }
    setIsSaving(false);
  };

  const handleDelete = async (colorFinishId) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_COLORFINISHES}/${colorFinishId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setSelectedRecord(null);
      setRecords((records) =>
        records.filter((el) => el.colorFinishId !== colorFinishId)
      );
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
            <Grid container alignItems="center">
              {columns.map((column) =>
                !column.isAction ? (
                  <Grid item xs={column.width} key={column.field} sx={{ p: 2 }}>
                    {!column.disableSearch && (
                      <InputControl
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearch}
                        className="searchInput"
                        margin="none"
                        fullWidth={false}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={column.width}
                    key={column.field}
                    sx={{ p: 2 }}
                    textAlign="right"
                  >
                    {user?.roles.includes("Admin") && (
                      <Tooltip title="Add New" placement="top" arrow>
                        <Fab
                          color="primary"
                          aria-label="add"
                          onClick={() => openInDrawer("add")}
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "69vh" }}>
              <TblContainer>
                {records.length === 0 && <caption>No Record Found!</caption>}
                <TblHead />
                <TableBody>
                  {records &&
                    recordsAfterPagingAndSorting().map((record, index) => (
                      <TableRow
                        key={record.colorFinishId}
                        selected={selectedIndex === index}
                        onClick={() => setSelectedIndex(index)}
                        hover
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.colorFinishId}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.createdDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.modifiedDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Details" placement="top" arrow>
                            <IconButton
                              aria-label="details"
                              onClick={() => openInDrawer("details", record)}
                            >
                              <ArticleIcon />
                            </IconButton>
                          </Tooltip>
                          {user?.roles.includes("Admin") && (
                            <>
                              <Tooltip title="Edit" placement="top" arrow>
                                <IconButton
                                  color="primary"
                                  aria-label="edit"
                                  onClick={() => openInDrawer("edit", record)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  color="error"
                                  aria-label="delete"
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure to delete this record?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleDelete(record.colorFinishId);
                                      },
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </TableContainer>
            <TblPagination />
          </Paper>
        </>
      )}

      {/* ContentDrawer */}
      <ContentDrawer
        contentDrawer={contentDrawer}
        setContentDrawer={setContentDrawer}
      >
        {IsForm ? (
          <FinishForm
            addOrEdit={addOrEdit}
            selectedRecord={selectedRecord}
            openInDrawer={openInDrawer}
            isSaving={isSaving}
          />
        ) : (
          <FinishDetails
            selectedRecord={selectedRecord}
            openInDrawer={openInDrawer}
          />
        )}
      </ContentDrawer>
    </>
  );
}

export default memo(FinishesList);
