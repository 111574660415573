import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import PasswordIcon from "@mui/icons-material/Password";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import { API_QUOTES, BASEURL } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useForm from "../../hooks/useForm";
import {
  currencyFormat,
  dateStamp,
  getRandomColor,
} from "../../scripts/Scripts";

const objRecord = {
  quoteId: 0,
  customerPin: "",
  signature: "",
};

export default function ApproveQuote() {
  const params = useParams();
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();

  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const sigCanvas = useRef({});
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [signError, setSignError] = useState("...");
  const [quote, setQuote] = useState();

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("quoteId" in input)
      temp.quoteId = input.quoteId ? "" : "Quote ID is required.";

    if ("customerPin" in input)
      temp.customerPin = input.customerPin ? "" : "Customer PIN is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  objRecord.quoteId = params.quoteId;
  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    objRecord,
    true,
    validate
  );

  // GET Quote
  const fetchRequest = async () => {
    // GET Request using fetch
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };

    const results = await ApiFetch(
      `${API_QUOTES}/${params.quoteId}`,
      requestOptions,
      setNotify
    );
    if (results) {
      const quoteInfo = JSON.parse(results.quoteInfo);
      setQuote({ ...results, quoteInfo: quoteInfo });
    }
  };

  useEffect(() => {
    (async () => await fetchRequest())();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const alertStatus = (status) => {
    switch (status) {
      case "Request":
        return "warning";

      case "Sent":
        return "info";

      case "Approved":
        return "success";

      case "Lost":
        return "error";

      default:
        return "info";
    }
  };

  // Approve Quote
  const handleSubmit = async (e) => {
    setFetchError(false);
    e.preventDefault();
    if (validate()) {
      setIsSaving(true);
      const abortCtrl = new AbortController();

      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };

      try {
        const response = await fetch(`${API_QUOTES}/Approve/`, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          throw Error(error);
        }

        setQuote({ ...quote, status: "Approved", jobCreatedDate: new Date() });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  const handleSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      setSignError("Signature is empty");
      return;
    }

    setIsDialog(false);
    setSignError("...");
    setTrimmedDataURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setValues({
      ...values,
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };

  const handleSendPin = async () => {
    setFetchError(false);
    setIsSaving(true);
    const abortCtrl = new AbortController();

    // GET Request using fetch
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${API_QUOTES}/SendCode/${quote.quoteId}`,
        requestOptions,
        {
          signal: abortCtrl.signal,
        }
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        throw Error(error);
      }

      setFetchError(
        <Alert severity="success">PIN sent to {quote.customer.email}</Alert>
      );
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
      abortCtrl.abort();
    }
  };

  return (
    <Container maxWidth={false} sx={{ p: 1 }} disableGutters>
      {quote && (
        <Zoom in={true} style={{ transitionDelay: "100ms" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Paper sx={{ pb: 3, height: "100%" }}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ArticleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Quote" secondary="Details" />
                  </ListItem>
                </List>
                <Divider />
                <List sx={{ px: 3 }}>
                  <ListItem
                    secondaryAction={<ListItemText primary={quote.industry} />}
                  >
                    <ListItemText
                      primary={`ID: ${quote.quoteId}`}
                      secondary={dateStamp(
                        quote.modifiedDate
                          ? quote.modifiedDate
                          : quote.createdDate
                      )}
                    />
                  </ListItem>
                  <Divider component="li" variant="middle" />
                  <ListItem>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell align="right">Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quote.quoteItems.map((it, i) =>
                            it.isGroup ? (
                              <TableRow
                                key={i}
                                sx={{ backgroundColor: "whitesmoke" }}
                              >
                                <TableCell variant="head">
                                  {it.serviceName}
                                </TableCell>
                                <TableCell variant="head" align="right">
                                  {currencyFormat(it.price, quote.currency)}
                                </TableCell>
                              </TableRow>
                            ) : (
                              !it.serviceValue?.startsWith("https:") && (
                                <TableRow key={i}>
                                  <TableCell sx={{ px: 4 }}>
                                    <Typography sx={{ whiteSpace: "pre-line" }}>
                                      {it.serviceValue}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {it.serviceName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              )
                            )
                          )}
                          <TableRow>
                            <TableCell align="right" colSpan={2} variant="head">
                              Total:{" "}
                              {currencyFormat(quote.totalPrice, quote.currency)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ListItem>
                  {quote.notes && (
                    <ListItem>
                      <ListItemText
                        primary={quote.notes}
                        secondary="Extra Info : (NOTES)"
                        sx={{ whiteSpace: "pre-line" }}
                      />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText
                      primary={quote.user && quote.user}
                      secondary="Shop Title"
                    />
                  </ListItem>
                  {Object.keys(quote.quoteInfo).length > 0 &&
                    quote.quoteInfo.constructor === Object && (
                      <>
                        <Divider component="li" variant="middle" />
                        {Object.keys(quote.quoteInfo).map((k) => (
                          <ListItem key={k}>
                            <ListItemText
                              sx={{
                                whiteSpace: "pre-line",
                              }}
                              primary={quote.quoteInfo[k]}
                              secondary={k.trim()}
                            />
                          </ListItem>
                        ))}
                      </>
                    )}
                  <Divider component="li" variant="middle" />
                  <ListItem>
                    <Grid container spacing={1} alignItems="center">
                      {quote.quoteItems.map(
                        (it, i) =>
                          it.serviceValue?.startsWith("https:") && (
                            <Grid item key={i} xs={3}>
                              <Card>
                                <CardMedia
                                  component="img"
                                  height="168"
                                  image={it.serviceValue}
                                  alt={it.serviceName}
                                  loading="lazy"
                                />
                                <CardContent sx={{ p: 1 }}>
                                  <Typography
                                    variant="caption"
                                    component="p"
                                    sx={{ textTransform: "capitalize" }}
                                    textAlign="center"
                                    noWrap
                                  >
                                    {it.serviceName}
                                  </Typography>
                                  <Divider light />
                                </CardContent>
                                <Divider light />
                              </Card>
                            </Grid>
                          )
                      )}

                      {quote.quoteImages.map((img, i) => (
                        <Grid item key={i} xs={3}>
                          <Card>
                            <CardMedia
                              component="img"
                              height="168"
                              image={`${BASEURL}/Img/${img.imageName}`}
                              alt={img.imageName}
                              loading="lazy"
                            />
                            <CardContent
                              sx={{
                                p: 1,
                                backgroundColor: getRandomColor(
                                  img.imageCategory
                                ),
                              }}
                            >
                              <Typography
                                variant="caption"
                                component="p"
                                sx={{ textTransform: "capitalize" }}
                                textAlign="center"
                                noWrap
                              >
                                {img.imageCategory}
                              </Typography>
                              <Divider light />
                            </CardContent>
                            <Divider light />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ height: "100%" }}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ContactPageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Contact" secondary="Details" />
                  </ListItem>
                </List>
                <Divider />
                <List sx={{ px: 3 }}>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.email}
                      secondary="Email"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.firstName}
                      secondary="First Name"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.lastName}
                      secondary="Last Name"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.phoneNumber}
                      secondary="Phone No."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.address}
                      secondary="Address"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.city}
                      secondary="City"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.state}
                      secondary="State"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.country}
                      secondary="Country"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.customer.zipCode}
                      secondary="Zip Code"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={quote.company} secondary="Company" />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={quote.taxNumber}
                      secondary="Tax No."
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} textAlign="center">
              <Alert
                action={
                  <ButtonControl
                    component={Link}
                    to={`/job-details/${quote.quoteId}`}
                    state={{ customerEmail: quote.customer.email }}
                    text="Back"
                    variant="inherit"
                    color="inherit"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => delete user.onHold}
                  />
                }
                severity={alertStatus(quote.status)}
              >
                {quote.status}
              </Alert>
              <Divider />
              {quote.signature && (
                <Card variant="outlined" sx={{ mt: 2 }}>
                  <CardMedia
                    component="img"
                    height="192"
                    image={quote.signature}
                    alt="signature"
                    sx={{ p: 1, objectFit: "contain" }}
                  />
                </Card>
              )}

              {!quote.jobCreatedDate && (
                <form onSubmit={handleSubmit}>
                  <ButtonControl
                    text="Send PIN"
                    variant="outlined"
                    size="small"
                    endIcon={<PasswordIcon />}
                    onClick={handleSendPin}
                    sx={{ mt: 2 }}
                  />
                  <input
                    name="quoteId"
                    value={values.quoteId}
                    readOnly
                    hidden
                  />
                  <InputControl
                    type="password"
                    label="Customer PIN"
                    name="customerPin"
                    value={values.customerPin}
                    onChange={handleInputChange}
                    error={errors.customerPin}
                  />
                  <Card variant="outlined" sx={{ mt: 1.5, mb: 2 }}>
                    {trimmedDataURL && (
                      <>
                        <CardMedia
                          component="img"
                          height="192"
                          image={trimmedDataURL}
                          alt="signature"
                          sx={{ p: 1, objectFit: "contain" }}
                        />
                        <Divider />
                      </>
                    )}
                    <CardActions>
                      <ButtonControl
                        text="Signature"
                        variant="text"
                        color="primary"
                        startIcon={<DriveFileRenameOutlineSharpIcon />}
                        fullWidth
                        onClick={() => {
                          setIsDialog(true);
                          setSignError("...");
                        }}
                      >
                        Share
                      </ButtonControl>
                    </CardActions>
                  </Card>
                  <Box component="div" textAlign="left" px={1}>
                    <a
                      href="https://2wrap.com/terms-of-service/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Term &amp; Conditions
                    </a>
                    <FormControl fullWidth>
                      <FormControlLabel
                        label="I agree to terms and conditions"
                        control={<Checkbox />}
                        onChange={(e) => setIsAgree(e.target.checked)}
                      />
                      <FormControlLabel
                        label="Authority to Sign"
                        control={<Checkbox />}
                        onChange={(e) => setIsAuthor(e.target.checked)}
                      />
                    </FormControl>
                    <Typography variant="body2" color="text.secondary">
                      The person signing below on behalf of any party hereby
                      represents and warrants that s/he or it is signing with
                      full and complete authority to bind the party on whose
                      behalf of whom s/he or it is signing, to each and every
                      terms of this Agreement.
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }}>
                    {fetchError ? (
                      fetchError
                    ) : (
                      <Avatar>
                        <TaskAltIcon />
                      </Avatar>
                    )}
                  </Divider>
                  {trimmedDataURL && !isSaving && isAgree && isAuthor ? (
                    <ButtonControl
                      type="submit"
                      text="Approve"
                      startIcon={<TaskAltIcon />}
                      color="success"
                    />
                  ) : (
                    <ButtonControl
                      text="Approve"
                      startIcon={<TaskAltIcon />}
                      isSaving={isSaving}
                      disabled
                    />
                  )}
                </form>
              )}
            </Grid>
          </Grid>
        </Zoom>
      )}

      {/* Signature canvas popupDialog */}
      {quote && !quote.jobCreatedDate && (
        <Dialog open={isDialog} fullScreen>
          <DialogTitle>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" light flexItem />}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <DriveFileRenameOutlineSharpIcon />
              <Typography variant="subtitle1">
                Quote ID: {quote.quoteId}
              </Typography>
              <ButtonControl
                text="Cancel"
                variant="outlined"
                color="error"
                endIcon={<CancelIcon />}
                onClick={() => setIsDialog(false)}
              />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box
              component="div"
              sx={{
                margin: "0 auto",
                height: "100%",
                border: "1px dashed grey",
              }}
              maxWidth="md"
            >
              <SignatureCanvas
                penColor="blue"
                canvasProps={{ className: "signCanvas" }}
                ref={sigCanvas}
              />
            </Box>
          </DialogContent>
          <Typography component="p" color="error" textAlign="center">
            {signError}
          </Typography>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ p: 3 }}
          >
            <Tooltip title="Clear">
              <Fab onClick={() => sigCanvas.current.clear()}>
                <ClearIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Save">
              <Fab color="primary" onClick={handleSignature}>
                <CheckIcon />
              </Fab>
            </Tooltip>
          </Stack>
        </Dialog>
      )}
    </Container>
  );
}
