import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import {
  Button,
  ButtonGroup,
  Divider,
  Fab,
  Grid,
  Paper,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ContentDrawer from "../../components/ContentDrawer";
import useComponentContext from "../../hooks/useComponentContext";
import useScheduler from "../../hooks/useScheduler";
import InstallForm from "./InstallForm";

function InstallScheduler() {
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [contentDrawer, setContentDrawer] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [days, setDays] = useState(7);
  const interval = 30;
  const [tformat, setTformat] = useState(12);
  const [hours, setHours] = useState({
    sT: "0800",
    eT: "2000",
  });

  const handleDays = (event, val) => {
    setDays(val);
  };

  const handleEdit = () => setContentDrawer(true);

  const { TblDate, TblContainer, TblBody, EventListeners } = useScheduler(
    startDate,
    days,
    interval,
    tformat,
    hours,
    handleEdit,
    setNotify
  );

  useEffect(() => {
    EventListeners();

    return () => {};
  }, [EventListeners]);

  return (
    <>
      <Toolbar component={Paper} square disableGutters sx={{ mb: 0.15 }}>
        <Grid container alignItems="center">
          <Grid
            item
            xs
            display="flex"
            justifyContent={{ xs: "center", md: "start" }}
            alignItems="center"
            sx={{ p: 2 }}
          >
            <ButtonGroup variant="text">
              <Button
                onClick={() => {
                  startDate.setDate(startDate.getDate() - days);
                  setStartDate(new Date(startDate));
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Button onClick={() => setStartDate(new Date())}>today</Button>
              <Button
                onClick={() => {
                  startDate.setDate(startDate.getDate() + days);
                  setStartDate(new Date(startDate));
                }}
              >
                <ArrowForwardIcon />
              </Button>
              <Button size="large" startIcon={<CalendarMonthIcon />}>
                <Typography sx={{ textTransform: "capitalize" }} noWrap>
                  {TblDate()}
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            xs
            display="flex"
            justifyContent={{ xs: "center", md: "end" }}
            alignItems="center"
            sx={{ p: 2 }}
          >
            {days <= 7 && (
              <ToggleButton
                value=""
                color="error"
                selected={hours.sT === "0000" ? false : true}
                sx={{ mr: 1 }}
                onChange={() =>
                  setHours(
                    hours?.sT === "0000"
                      ? { sT: "0800", eT: "2000" }
                      : { sT: "0000", eT: "2400" }
                  )
                }
              >
                {hours.sT === "0000" ? (
                  <AccessTimeIcon />
                ) : (
                  <HistoryToggleOffIcon />
                )}
              </ToggleButton>
            )}
            <ToggleButtonGroup
              value={days}
              exclusive
              onChange={handleDays}
              aria-label="days alignment"
            >
              <ToggleButton value={1} aria-label="day">
                Day
              </ToggleButton>
              <ToggleButton value={7} aria-label="week">
                Week
              </ToggleButton>
              <ToggleButton value={30} aria-label="month">
                Month
              </ToggleButton>
            </ToggleButtonGroup>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <Tooltip title="Add New" placement="top" arrow>
              <Fab
                color="primary"
                aria-label="add"
                onClick={() => setContentDrawer(true)}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "77.2vh" }}>
          <TblContainer>
            <TblBody />
          </TblContainer>
        </TableContainer>
      </Paper>

      {/* ContentDrawer */}
      <ContentDrawer
        contentDrawer={contentDrawer}
        setContentDrawer={setContentDrawer}
      >
        <InstallForm />
      </ContentDrawer>
    </>
  );
}

export default memo(InstallScheduler);
