import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { memo, useRef } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
  subject: "",
  template: "",
  pdf: "",
};

function EmailTemplateForm({
  addOrEdit,
  selectedRecord,
  openInDrawer,
  isSaving,
}) {
  const templateEditorRef = useRef(null);
  const pdfEditorRef = useRef(null);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";
    if ("subject" in input)
      temp.subject = input.subject ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const templateVars = {
    subject: ["{FULL-NAME}"],
    email: ["{DATE}", "{EMAIL}", "{FULL-NAME}"],
    pdf: ["{DATE}", "{EMAIL}", "{FULL-NAME}"],
  };
  const vars = (id) => {
    switch (id) {
      case 2:
      case 8:
      case 9:
      case 10:
      case 12:
      case 14:
      case 15:
        templateVars.subject = ["{INDUSTRY}", "{QUOTEID}", "{CUSTOMER-NAME}"];
        templateVars.email = [
          "{DATE}",
          "{INDUSTRY}",
          "{QUOTEID}",
          "{CUSTOMER-NAME}",
          "{SHOP}",
          "{USER-NAME}",
        ];
        templateVars.pdf = [
          "{DATE}",
          "{INDUSTRY}",
          "{QUOTEID}",
          "{QUOTE-DATE}",
          "{QUOTE-EINFO}",
          "{QUOTE-IMGS}",
          "{QUOTE-NOTES}",
          "{QUOTE-ITEMS}",
          "{CUSTOMER-EMAIL}",
          "{CUSTOMER-NAME}",
          "{CUSTOMER-PHONE}",
          "{CUSTOMER-ADDRESS}",
          "{CUSTOMER-CITY}",
          "{CUSTOMER-STATE}",
          "{CUSTOMER-COUNTRY}",
          "{CUSTOMER-ZIP}",
          "{CUSTOMER-SIGN}",
          "{SHOP}",
          "{USER-NAME}",
        ];
        return templateVars;

      case 4:
      case 6:
        templateVars.subject = [];
        templateVars.email = ["{DATE}", "{EMAIL}", "{PIN}"];
        templateVars.pdf = ["{DATE}", "{EMAIL}", "{PIN}"];
        return templateVars;

      default:
        return templateVars;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.template = templateEditorRef.current.getContent();
    values.pdf = pdfEditorRef.current.getContent();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          <ListItem
            secondaryAction={
              <Tooltip title="Close" placement="bottom" arrow>
                <Button
                  variant="text"
                  endIcon={<CloseIcon />}
                  onClick={() => openInDrawer()}
                >
                  Close
                </Button>
              </Tooltip>
            }
          >
            {selectedRecord ? (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <EditIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Edit" secondary="Email Template" />
              </>
            ) : (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add New" secondary="Email Template" />
              </>
            )}
          </ListItem>
        </List>
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">{`SUBJECT {vars}:`}</Typography>
            </Grid>
            {vars(values.emailTemplateId).subject.map((el, i) => (
              <Grid item key={i}>
                <Chip label={el} />
              </Grid>
            ))}
          </Grid>
          <InputControl
            label="Subject"
            name="subject"
            value={values.subject}
            onChange={handleInputChange}
            error={errors.subject}
          />
          <FormControl fullWidth margin="normal">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">{`EMAIL {vars}:`}</Typography>
              </Grid>
              {vars(values.emailTemplateId).email.map((el, i) => (
                <Grid item key={i}>
                  <Chip label={el} />
                </Grid>
              ))}
            </Grid>
            <FormLabel>Email Editor</FormLabel>
            <Editor
              apiKey="hcrnh25lrnya5i165p07hodgt9i4hs77d7atdp75o1myodqy"
              onInit={(evt, editor) => (templateEditorRef.current = editor)}
              initialValue={values.template}
              init={{
                min_height: 300,
                branding: false,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "preview",
                  "help",
                  "wordcount",
                ],
                toolbar1:
                  "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat image preview",

                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                convert_urls: false,
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">{`PDF {vars}:`}</Typography>
              </Grid>
              {vars(values.emailTemplateId).pdf.map((el, i) => (
                <Grid item key={i}>
                  <Chip label={el} />
                </Grid>
              ))}
            </Grid>
            <FormLabel>PDF Editor</FormLabel>
            <Editor
              apiKey="hcrnh25lrnya5i165p07hodgt9i4hs77d7atdp75o1myodqy"
              onInit={(evt, editor) => (pdfEditorRef.current = editor)}
              initialValue={values.pdf}
              init={{
                min_height: 300,
                branding: false,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "preview",
                  "help",
                  "wordcount",
                ],
                toolbar1:
                  "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat image preview",

                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                convert_urls: false,
              }}
            />
          </FormControl>
          <ButtonControl type="submit" text="Save" isSaving={isSaving} />
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(EmailTemplateForm);
