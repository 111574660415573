import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { API_HUB } from "../context/ApiEndPoints";
import { ComponentProvider } from "../context/ComponentContext";
import useAppContext from "../hooks/useAppContext";
import Appbar from "./Appbar";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";

export default function Layout() {
  const { user, setHubCon, alerts, setAlerts, setAlertCount } = useAppContext();
  const location = useLocation();

  const connection = new HubConnectionBuilder()
    .withUrl(API_HUB, { accessTokenFactory: () => user?.token })
    .configureLogging(LogLevel.Information)
    .build();

  let timeOut = 5000;
  const startHub = async () => {
    try {
      connection.on("RecieveUserAlerts", (exAlerts) => {
        setAlerts(exAlerts);
      });

      connection.on("RecieveAlert", (nwAlert) => {
        setAlerts((alerts) => [nwAlert, ...alerts]);
      });

      await connection.start();
      await connection.invoke("GetAlerts");
      setHubCon(connection);
      timeOut = 5000;
    } catch (e) {
      console.error(e);
      setTimeout(async () => {
        await startHub();
        if (timeOut < 30000) timeOut += 5000;
        console.log(timeOut);
      }, timeOut);
    }
  };

  connection.onclose(async () => {
    setHubCon();
    if (!user) return;

    try {
      await startHub();
    } catch (e) {
      console.error(e);
    }
  });

  // start hub connection
  useEffect(() => {
    if (connection?.state === "Disconnected" && user) {
      try {
        (async () => await startHub())();
      } catch (e) {
        console.error(e);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Array.isArray(alerts)) {
      setAlertCount(
        alerts.reduce(
          (el, { dismiss }) => (dismiss === false ? (el += 1) : el),
          0
        )
      );
    }

    return () => {};
    // eslint-disable-next-line
  }, [alerts]);

  return user?.token ? (
    <ComponentProvider>
      {user?.onHold ? (
        <Outlet />
      ) : (
        <Appbar connection={connection}>
          <Container maxWidth="false" disableGutters sx={{ p: 1 }}>
            <Outlet />
          </Container>
        </Appbar>
      )}
      <ConfirmDialog />
      <Notification />
    </ComponentProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
}
