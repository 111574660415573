import ChatIcon from "@mui/icons-material/Chat";
import PersonIcon from "@mui/icons-material/Person";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  Grid,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomerCard from "../../components/CustomerCard";
import QuoteCard from "../../components/QuoteCard";
import TabPanel from "../../components/TabPanel";
import DesignDetails from "../Designs/DesignDetails";
import InstallScheduler from "../Install/InstallScheduler";
import QuoteComments from "../Quotes/QuoteComments";
import QuoteDetails from "../Quotes/QuoteDetails";

export default function JobDetails() {
  const params = useParams();
  const { state } = useLocation();
  const steps = ["Quote", "Design", "Installation", "Invoice"];
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2, mb: 1 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <QuoteDetails quoteId={params.quoteId} />}
        {activeStep === 1 && <DesignDetails quoteId={params.quoteId} />}
        {activeStep === 2 && <InstallScheduler />}
        {activeStep === 3 && <Typography>...not so hurry tiger!</Typography>}
      </Grid>
      <Grid item xs={12} md={4}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="info cards">
          <Tab icon={<ChatIcon />} />
          <Tab icon={<PersonIcon />} />
          <Tab icon={<RequestQuoteIcon />} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <QuoteComments quoteId={params.quoteId} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerCard customerEmail={state?.customerEmail} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <QuoteCard quoteId={params.quoteId} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
