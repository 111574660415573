import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../components/TabPanel";
import RolesList from "../Roles/RolesList";
import ShopsList from "../Shops/ShopsList";
import UsersList from "./UsersList";

export default function Users() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, nwValue) => {
    setTabValue(nwValue);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
        <Tab label="Shops" />
        <Tab label="Users" />
        <Tab label="Roles" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ShopsList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UsersList />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <RolesList />
      </TabPanel>
    </>
  );
}
