import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Card,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import ColorSelect from "../../components/ColorSelect";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import FilesUploader from "../../components/FilesUploader";
import ServiceCard from "../../components/ServiceCard";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import FormCustomerInfo from "./FormCustomerInfo";

const objRecord = {
  industry: "Aeronautics",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
  company: "",
  taxNumber: "",
  whereFindUs: "",
};

function FormAeronautics() {
  const { user } = useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [responseId, setResponseId] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const nextStep = () => {
    // ARRAY: create selected services array;
    const x = Object.keys(values).filter((k) => k.startsWith("_") && values[k]);
    setItems(x);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      const quoteInfo = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = data;
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);
      record.quoteInfo = JSON.stringify(quoteInfo);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add exteriorFile values to formData
      if (values.exteriorDesignWrapFile) {
        for (const val of Object.values(values.exteriorDesignWrapFile)) {
          formData.append("exteriorDesignWrapFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };

      try {
        const response = await fetch(API_QUOTES, requestOptions);
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setStep(step + 1);
        setValues({ ...objRecord });
        setErrors({});
        setResponseId(json);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Slide
          direction="right"
          in={step === 0 ? true : false}
          style={{ display: step === 0 ? "flex" : "none" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "ExteriorStripingColor",
                      "ExteriorStripingColorImg",
                      "ExteriorStripingParts",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_ExteriorStriping"
                    icon="wi-aeronautics-exterior"
                    title="Exterior Striping"
                  >
                    <ColorSelect
                      aria-label="exterior striping Color"
                      label="Striping Color"
                      name="ExteriorStripingColor"
                      values={values}
                      imgSrc="ExteriorStripingColorImg"
                      onChange={(e) =>
                        handleInputChange(e, "_ExteriorStriping")
                      }
                    />
                    <InputControl
                      label="Which Part(s)"
                      name="ExteriorStripingParts"
                      onChange={(e) =>
                        handleInputChange(e, "_ExteriorStriping")
                      }
                      multiline
                      rows={3}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "ExteriorDesignWrapOption",
                      "exteriorDesignWrapFile",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_ExteriorDesignWrap"
                    icon="wi-aeronautics-exterior"
                    title="Exterior Design Wrap"
                  >
                    <RadioGroupControl
                      aria-label="exterior design wrap"
                      name="ExteriorDesignWrapOption"
                      onChange={(e) =>
                        handleInputChange(e, "_ExteriorDesignWrap")
                      }
                      options={[
                        {
                          label: "Design Request",
                          value: "Design Request",
                        },
                        {
                          label: "I Have a Design",
                          value: "I Have a Design",
                        },
                      ]}
                    />
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Upload Design / Example</InputLabel>
                      <FilesUploader
                        inputName="exteriorDesignWrapFile"
                        accept="image/*"
                        values={values}
                        setValues={setValues}
                      />
                    </FormControl>
                    {/* <FormControl margin="dense" fullWidth>
                      <label htmlFor="exteriorDesignWrap-file">
                        <input
                          id="exteriorDesignWrap-file"
                          name="exteriorDesignWrapFile"
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={showPreview}
                          style={{ display: "none" }}
                        />
                        <ButtonControl
                          startIcon={<CloudUploadIcon />}
                          variant="outlined"
                          text="Upload Your Design / Example"
                          component="span"
                          fullWidth
                        />
                      </label>
                      {values.exteriorDesignWrapFile &&
                        renderImageList(imgList.exteriorDesignWrapFile)}
                    </FormControl> */}
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "ExteriorAccentWrapColor",
                      "ExteriorAccentWrapColorImg",
                      "ExteriorAccentWrapParts",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_ExteriorAccentWrap"
                    icon="wi-aeronautics-exterior"
                    title="Exterior Accent Wrap"
                  >
                    <ColorSelect
                      aria-label="accent wrap Color"
                      label="Accent Wrap Color"
                      name="ExteriorAccentWrapColor"
                      values={values}
                      imgSrc="ExteriorAccentWrapColorImg"
                      onChange={(e) =>
                        handleInputChange(e, "_ExteriorAccentWrap")
                      }
                    />
                    <InputControl
                      label="Which Part(s)"
                      name="ExteriorAccentWrapParts"
                      onChange={(e) =>
                        handleInputChange(e, "_ExteriorAccentWrap")
                      }
                      multiline
                      rows={3}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={[
                      "InteriorWrapColor",
                      "InteriorWrapColorImg",
                      "InteriorWrapParts",
                    ]}
                    values={values}
                    setValues={setValues}
                    switchName="_InteriorWrap"
                    icon="wi-aeronautics-interior"
                    title="Interior Wrap"
                  >
                    <ColorSelect
                      aria-label="color"
                      label="Color"
                      name="InteriorWrapColor"
                      values={values}
                      imgSrc="InteriorWrapColorImg"
                      onChange={(e) => handleInputChange(e, "_InteriorWrap")}
                    />
                    <InputControl
                      label="Which Part(s)"
                      name="InteriorWrapParts"
                      onChange={(e) => handleInputChange(e, "_InteriorWrap")}
                      multiline
                      rows={3}
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={["InteriorWindowTintOption"]}
                    values={values}
                    setValues={setValues}
                    switchName="_InteriorWindowTint"
                    icon="wi-aeronautics-interior"
                    title="Interior Window Tint"
                  >
                    <CheckboxControl
                      label="Window Tint"
                      name="InteriorWindowTintOption"
                      onChange={(e) =>
                        handleInputChange(e, "_InteriorWindowTint")
                      }
                    />
                  </ServiceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ServiceCard
                    services={["OtherDetails"]}
                    values={values}
                    setValues={setValues}
                    switchName="_Other"
                    icon="wi-other"
                    title="Other"
                  >
                    <InputControl
                      label="Please Specify"
                      name="OtherDetails"
                      multiline
                      rows={3}
                      onChange={(e) => handleInputChange(e, "_Other")}
                    />
                  </ServiceCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} textAlign="center">
              <Fab
                aria-label="go back"
                component={Link}
                to="/jobs"
                sx={{ mb: 3 }}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography color="text.secondary">
                Select services form left and click CONFIRM once done
              </Typography>
              <Divider variant="middle" sx={{ my: 2 }} />
              {Object.keys(values).some(
                (k) => /^[A-Z]/.test(k[0]) && values[k]
              ) ? (
                <ButtonControl onClick={nextStep} text="CONFIRM" />
              ) : (
                <ButtonControl text="CONFIRM" disabled />
              )}
            </Grid>
          </Grid>
        </Slide>

        <Slide
          direction="left"
          in={step === 1 ? true : false}
          style={{ display: step === 1 ? "flex" : "none" }}
        >
          <Grid container>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ height: "100%" }}></Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormCustomerInfo
                    values={values}
                    setValues={setValues}
                    handleInputChange={handleInputChange}
                    validate={validate}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} textAlign="center">
              <Fab onClick={prevStep} aria-label="go back" sx={{ mb: 3 }}>
                <ArrowBackIcon />
              </Fab>
              <Typography color="text.secondary">Selected services</Typography>
              <List>
                {items.map((item) => (
                  <ListItem key={item}>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText
                      primary={item
                        .slice(1)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider variant="middle" sx={{ my: 2 }}>
                {fetchError}
              </Divider>
              {!isSaving ? (
                <ButtonControl
                  type="submit"
                  text="Submit"
                  isSaving={isSaving}
                />
              ) : (
                <ButtonControl
                  text="...Processing"
                  isSaving={isSaving}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Slide>
      </form>

      <Slide
        direction="left"
        in={step === 2 ? true : false}
        style={{ display: step === 2 ? "flex" : "none" }}
      >
        <Grid container>
          <Grid item xs={12} md={9}>
            <Alert severity="success">Quote Created Successfully!</Alert>
          </Grid>
          <Grid item xs={12} md={3} px={5} textAlign="center">
            {responseId && (
              <ButtonControl
                text="Done"
                color="success"
                startIcon={<CheckIcon />}
                component={Link}
                to={`/job-details/${responseId.quoteId}`}
              />
            )}
          </Grid>
        </Grid>
      </Slide>
    </>
  );
}

export default memo(FormAeronautics);
