import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from "@mui/material";
import React, { memo } from "react";
import { API_CUSTOMERS } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";

function CustomerCard(props) {
  const { records, isLoading, fetchError } = useFetchList(
    `${API_CUSTOMERS}/${props.customerEmail}`
  );

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records && (
        <Paper sx={{ p: 2 }}>
          {records && (
            <List
              subheader={
                <ListSubheader
                  component="div"
                  sx={{ color: "text.primary", fontSize: "large" }}
                >
                  Customer
                </ListSubheader>
              }
            >
              <Divider variant="middle" component="li" light />
              <ListItem>
                <ListItemText primary={records.email} secondary="Email" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.firstName}
                  secondary="First Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.lastName}
                  secondary="Last Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.phoneNumber}
                  secondary="Phone No."
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.address} secondary="Address" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.city} secondary="City" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.state} secondary="State" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.country} secondary="Country" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.zipCode} secondary="Zip Code" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.company} secondary="Company" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.taxNumber} secondary="Tax No." />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.whereFindUs}
                  secondary="Found Us"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ whiteSpace: "pre-line" }}
                  primary={records.notes}
                  secondary="Notes (About)"
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.user} secondary="Shop" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.createdDate}
                  secondary="Created On Date"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.modifiedDate}
                  secondary="Modified On Date"
                />
              </ListItem>
            </List>
          )}
        </Paper>
      )}
    </>
  );
}

export default memo(CustomerCard);
