import FaceIcon from "@mui/icons-material/Face";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import { API_USERS_PROFILE, FALLBACKIMG } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useForm from "../../hooks/useForm";

export default function UserProfile() {
  const { user, setUser } = useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);

  const validate = (input = values) => {
    let temp = { ...errors };
    if ("shopTitle" in input)
      temp.shopTitle = input.shopTitle ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    user,
    true,
    validate
  );

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setValues({ ...values, imageFile, imageName: x.target.result });
      };
      reader.readAsDataURL(imageFile);
    } else {
      setValues({
        ...values,
        imageFile: null,
        imageName: FALLBACKIMG,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      //Create formData with image file
      const formData = new FormData();
      for (const [k, v] of Object.entries(values)) {
        formData.append(k, v);
      }

      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { Authorization: "Bearer " + user.token },
        body: formData,
      };
      const results = await ApiFetch(
        API_USERS_PROFILE,
        requestOptions,
        setNotify,
        "Profile Updated Successfully!"
      );

      if (results) {
        setUser(values);
        localStorage.setItem("X-Access-Token", JSON.stringify(values));
      }
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item component={Paper} xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Profile" secondary="Contact Info" />
            </ListItem>
          </List>
          <Divider />
          <Paper elevation={0} sx={{ p: 3 }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="flex-start"
              spacing={2}
            >
              <Card sx={{ maxWidth: 200 }}>
                <CardMedia
                  height="100%"
                  component="img"
                  image={values.imageName ? values.imageName : FALLBACKIMG}
                  alt={values.imageName}
                  loading="lazy"
                />
                <CardActions sx={{ justifyContent: "center" }}>
                  <label htmlFor="user-file">
                    <input
                      type="file"
                      id="user-file"
                      name="imageFile"
                      accept="image/*"
                      onChange={showPreview}
                      hidden
                    />
                    <ButtonControl
                      component="span"
                      text="Upload"
                      startIcon={<PhotoCamera />}
                    />
                  </label>
                </CardActions>
              </Card>
              <Box>
                <InputControl
                  label="Shop Title"
                  name="shopTitle"
                  value={values.shopTitle}
                  onChange={handleInputChange}
                  error={errors.shopTitle}
                />
                <InputControl
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleInputChange}
                  error={errors.firstName}
                />
                <InputControl
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                />
                <InputControl
                  type="tel"
                  label="Phone No."
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                  error={errors.phoneNumber}
                />
                <InputControl
                  label="Country"
                  name="country"
                  value={values.country}
                  onChange={handleInputChange}
                  error={errors.country}
                  disabled
                />
                <ButtonControl type="submit" text="Save" isSaving={isSaving} />
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
