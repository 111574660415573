import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Chip,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiFetch from "../../components/ApiFetch";
import InputControl from "../../components/controls/InputControl";
import { API_QUOTES } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useTable from "../../hooks/useTable";
import { currencyFormat } from "../../scripts/Scripts";
dayjs.extend(localizedFormat);

const columns = [
  { field: "quoteId", label: "ID", width: 1 },
  { field: "industry", label: "Industry / Tile", width: 1 },
  {
    field: "stage",
    label: "Stage",
    width: 1,
    filters: [
      { value: "", label: "None" },
      { value: "1", label: "Quote" },
      { value: "2", label: "Design" },
    ],
  },
  {
    field: "status",
    label: "Status",
    width: 2,
    filters: [
      { value: "", label: "None" },
      { value: "Request", label: "Request" },
      { value: "Sent", label: "Sent" },
      { value: "Todo", label: "Todo" },
      { value: "Waiting", label: "Waiting" },
      { value: "Approved", label: "Approved" },
      { value: "Lost", label: "Lost" },
    ],
  },
  { field: "totalPrice", label: "Total Price", width: 1 },
  { field: "customer", label: "Customer", width: 2 },
  { field: "user", label: "Shop", width: 1 },
  { field: "createdDate", label: "Created On", width: 1 },
  { field: "modifiedDate", label: "Modified On", width: 1 },
  {
    field: "action",
    label: "Actions",
    width: 1,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

const stageStr = (int) => {
  switch (int) {
    case 1:
      return (
        <Chip
          color="primary"
          icon={<LooksOneIcon />}
          label="Quote"
          sx={{ borderRadius: 1 }}
        />
      );
    case 2:
      return (
        <Chip
          color="warning"
          icon={<LooksTwoIcon />}
          label="Design"
          sx={{ borderRadius: 1 }}
        />
      );
    case 3:
      return "Installation";
    case 4:
      return "Invoice";
    default:
      return "Quote";
  }
};

const alertStatus = (status) => {
  switch (status) {
    case "Request":
    case "Todo":
      return "warning";

    case "Sent":
    case "Waiting":
      return "info";

    case "Approved":
      return "success";

    case "Lost":
      return "error";

    default:
      return "info";
  }
};

function JobsList() {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog, scrhObjs, setScrhObjs } =
    useComponentContext();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_QUOTES);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const handleSearch = (e) => {
    const { name, value } = e.target;
    const index = scrhObjs.findIndex((el) => el.name === name);
    if (index >= 0) {
      scrhObjs[index] = { name, value };
    } else {
      scrhObjs.push({ name, value });
    }

    setScrhObjs((scrhObjs) => scrhObjs.filter((el) => el.value !== ""));
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setFilterFn({
        fn: (items) => {
          let x;
          for (const { name, value } of scrhObjs) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
          return items;
        },
      });
    }, 300);

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line
  }, [scrhObjs]);

  const handleDelete = async (quoteId) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
    };
    const results = await ApiFetch(
      `${API_QUOTES}/${quoteId}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setRecords((records) => records.filter((el) => el.quoteId !== quoteId));
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
            <Grid container alignItems="center">
              {columns.map((column) =>
                !column.isAction ? (
                  <Grid item xs={column.width} key={column.field} sx={{ p: 2 }}>
                    {!column.filters ? (
                      <InputControl
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        value={
                          scrhObjs.find((el) => el.name === column.field)
                            ?.value || ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleSearch(e)}
                        className="srchInput"
                        margin="none"
                        fullWidth={false}
                      />
                    ) : (
                      <TextField
                        select
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        value={
                          scrhObjs.find((el) => el.name === column.field)
                            ?.value || ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleSearch(e)}
                        className="srchInput"
                        margin="none"
                        fullWidth={true}
                      >
                        {column.filters.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={column.width}
                    key={column.field}
                    sx={{ p: 2 }}
                    textAlign="right"
                  >
                    <Tooltip title="Add New" placement="top" arrow>
                      <Fab
                        color="primary"
                        aria-label="add"
                        component={Link}
                        to="/add-quote"
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                )
              )}
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "69vh" }}>
              <TblContainer>
                {records.length === 0 && <caption>No Record Found!</caption>}
                <TblHead />
                <TableBody>
                  {records &&
                    recordsAfterPagingAndSorting().map((record, index) => (
                      <TableRow
                        key={record.quoteId}
                        selected={selectedIndex === index}
                        onClick={() => setSelectedIndex(index)}
                        hover
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.quoteId}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.industry}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {stageStr(record.stage)}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Alert severity={alertStatus(record.status)}>
                            {record.status}
                          </Alert>
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {currencyFormat(record.totalPrice, record.currency)}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.customer}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.user}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.createdDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.modifiedDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Edit" placement="top" arrow>
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              component={Link}
                              to={`/job-details/${record.quoteId}`}
                              state={{
                                customerEmail: record.customer.split(" ")[0],
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          {user && user.roles.includes("Admin") && (
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={() =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: `ID: ${record.quoteId} \n Are you sure to delete this record?`,
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleDelete(record.quoteId);
                                    },
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </TableContainer>
            <TblPagination />
          </Paper>
        </>
      )}
    </>
  );
}

export default memo(JobsList);
