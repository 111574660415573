import ArticleIcon from "@mui/icons-material/Article";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import PopupDialog from "../../components/PopupDialog";
import { BASEURL } from "../../context/ApiEndPoints";
import useComponentContext from "../../hooks/useComponentContext";

function CustomerDetails(props) {
  const { selectedRecord, openInDrawer, handleFileDelete } = props;
  const { setConfirmDialog } = useComponentContext();
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  return (
    selectedRecord && (
      <>
        <Paper>
          <List>
            <ListItem
              secondaryAction={
                <Tooltip title="Edit" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => openInDrawer("edit", selectedRecord)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <ArticleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Details" secondary="Customer" />
            </ListItem>
          </List>
          <Divider />
          <List sx={{ p: 3 }}>
            <ListItem>
              <ListItemText
                primary={
                  selectedRecord.block ? (
                    <BlockIcon color="error" />
                  ) : (
                    <BlockIcon color="inherit" />
                  )
                }
                secondary="Blocked"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.email} secondary="Email" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.firstName}
                secondary="First Name"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.lastName}
                secondary="Last Name"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.phoneNumber}
                secondary="Phone Number"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.address}
                secondary="Address"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.city} secondary="City" />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.state} secondary="State" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.country}
                secondary="Country"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.zipCode}
                secondary="Zip Code"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.company}
                secondary="Company"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.taxNumber}
                secondary="Tax No."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.notes}
                secondary="Notes (About)"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.whereFindUs}
                secondary="Found Us"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={selectedRecord.user} secondary="Shop" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={selectedRecord.createdDate}
                secondary="Created On Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  selectedRecord.modifiedDate && selectedRecord.modifiedDate
                }
                secondary="Modified On Date"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <Grid container spacing={1}>
                {selectedRecord.medias &&
                  selectedRecord.medias.map((el, i) => (
                    <Grid item key={i}>
                      <Card>
                        <CardHeader
                          action={
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                onClick={(e) =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete this file?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleFileDelete(e, el.mediaId);
                                    },
                                  })
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          title={el.mime}
                          titleTypographyProps={{
                            fontSize: "small",
                          }}
                        />
                        {el.mime.includes("image") ? (
                          <>
                            <CardActionArea
                              onClick={() => {
                                setPopupContent(
                                  `${BASEURL}/img/${el.fileName}`
                                );
                                setPopupDialog(true);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="144"
                                image={`${BASEURL}/img/${el.fileName}`}
                                alt={el.fileName}
                                loading="lazy"
                              />
                            </CardActionArea>
                            <CardContent sx={{ textAlign: "center" }}>
                              <Typography variant="subtitle2" p={1}>
                                ...
                              </Typography>
                              <Divider light />
                            </CardContent>
                          </>
                        ) : el.mime === "application/pdf" ? (
                          <CardContent sx={{ textAlign: "center" }}>
                            <embed
                              src={`${BASEURL}/img/${el.fileName}#view=FitV`}
                              type="application/pdf"
                              width="100%"
                              height="144"
                              loading="lazy"
                            />
                            <ButtonControl
                              component={Link}
                              href={`${BASEURL}/img/${el.fileName}`}
                              text="View"
                              variant="text"
                              size="medium"
                              startIcon={<DownloadRoundedIcon />}
                              download={el.fileName}
                              target="_blank"
                              sx={{ textTransform: "lowercase" }}
                            />
                            <Divider light />
                          </CardContent>
                        ) : (
                          <>
                            <CardMedia
                              component="img"
                              height="144"
                              image="img/file.png"
                              alt={el.fileName}
                              loading="lazy"
                            />
                            <CardContent sx={{ textAlign: "center" }}>
                              <ButtonControl
                                component={Link}
                                href={`${BASEURL}/img/${el.fileName}`}
                                text="Download"
                                variant="text"
                                size="medium"
                                startIcon={<DownloadRoundedIcon />}
                                download={el.fileName}
                                sx={{ textTransform: "lowercase" }}
                              />
                              <Divider light />
                            </CardContent>
                          </>
                        )}
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </ListItem>
          </List>
        </Paper>
        <PopupDialog popupDialog={popupDialog} setPopupDialog={setPopupDialog}>
          <img src={popupContent} alt={popupContent} />
        </PopupDialog>
      </>
    )
  );
}

export default memo(CustomerDetails);
