import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Avatar,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import FilesUploader from "../../components/FilesUploader";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_USERS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

const objRecord = {
  quoteId: 0,
  service: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  company: "",
  taxNumber: "",
  whereFindUs: "Social Media",
  notes: "",
  userId: "",
};

function InstallForm({ addOrEdit, selectedRecord, openInDrawer, isSaving }) {
  const { user } = useAppContext();

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";
    if ("userId" in input)
      temp.userId = input.userId ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(selectedRecord ? selectedRecord : objRecord, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const {
    records: usersSelect,
    isLoading: userIsLoading,
    fetchError: userFetchError,
  } = useFetchList(API_USERS);

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Installation" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Installation" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <Alert severity="warning">Not Confirmed</Alert>
          <SelectControl
            label="Select Quote"
            name="quoteId"
            value={values.quoteId}
            onChange={handleInputChange}
            options={[
              { value: "Social Media", label: "Social Media" },
              { value: "TV", label: "TV" },
              { value: "Friend", label: "Friend" },
              { value: "Google", label: "Google" },
              { value: "Other", label: "Other" },
            ]}
          />
          <Stack direction="row" spacing={1}>
            <SelectControl
              label="Select Service"
              name="service"
              value={values.service}
              onChange={handleInputChange}
              options={[
                { value: "Social Media", label: "Social Media" },
                { value: "TV", label: "TV" },
                { value: "Friend", label: "Friend" },
                { value: "Google", label: "Google" },
                { value: "Other", label: "Other" },
              ]}
              sx={{ m: 0 }}
            />
            <ButtonControl
              type="submit"
              variant="outlined"
              size="large"
              endIcon={<AddIcon />}
              isSaving={isSaving}
            />
          </Stack>
          <InputControl
            type="email"
            label="Start Date & Time"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
          <InputControl
            label="End Date & Time"
            name="firstName"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
          {userFetchError ||
            (userIsLoading && <CircularProgress size={20} color="inherit" />)}
          {usersSelect && (
            <>
              {user?.roles.includes("Admin") && (
                <SelectControl
                  label="Shop"
                  name="userId"
                  value={values.userId}
                  onChange={handleInputChange}
                  options={usersSelect}
                  error={errors.userId}
                  textIndex={4}
                />
              )}
              {user?.roles.includes("Admin") && (
                <SelectControl
                  label="User"
                  name="userId"
                  value={values.userId}
                  onChange={handleInputChange}
                  options={usersSelect}
                  error={errors.userId}
                  textIndex={4}
                />
              )}
              <ButtonControl
                type="submit"
                text="Save"
                isSaving={isSaving}
                sx={{ mr: 1 }}
              />
              <ButtonControl
                type="submit"
                text="Notify Customer"
                isSaving={isSaving}
              />
            </>
          )}
          <Divider sx={{ my: 2 }} />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Picture(s) Before Service</InputLabel>
            <FilesUploader
              inputName="customerFiles"
              accept="image/*"
              values={values}
              setValues={setValues}
            />
          </FormControl>
          <InputControl
            label="Recived Entity ID (If any)"
            name="notes"
            value={values.notes}
            onChange={handleInputChange}
            error={errors.notes}
          />
          <InputControl
            label="Additional Service Required (If any)"
            name="notes"
            value={values.notes}
            onChange={handleInputChange}
            error={errors.notes}
          />
          <InputControl
            label="Remarks"
            name="notes"
            value={values.notes}
            onChange={handleInputChange}
            error={errors.notes}
            multiline
            rows={3}
          />
          <ButtonControl
            type="submit"
            color="inherit"
            text="Approve"
            isSaving={isSaving}
          />
          <Divider sx={{ my: 2 }} />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Picture(s) After Service</InputLabel>
            <FilesUploader
              inputName="customerFiles"
              accept="image/*"
              values={values}
              setValues={setValues}
            />
          </FormControl>
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(InstallForm);
