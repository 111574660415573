import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import ContentDrawer from "../../components/ContentDrawer";
import InputControl from "../../components/controls/InputControl";
import { API_SHOPS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import useTable from "../../hooks/useTable";
import { dateStamp } from "../../scripts/Scripts";
import ShopDetails from "./ShopDetails";
import ShopForm from "./ShopForm";

const columns = [
  { field: "title", label: "Title", width: 2 },
  { field: "alias", label: "Alias", width: 2 },
  { field: "country", label: "Country", width: 2 },
  { field: "createdDate", label: "Created On", width: 2 },
  { field: "modifiedDate", label: "Modified On", width: 2 },
  {
    field: "action",
    label: "Actions",
    width: 2,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

function ShopsList() {
  const { user } = useAppContext();
  const { setNotify, setConfirmDialog } = useComponentContext();
  const [contentDrawer, setContentDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const { records, setRecords, isLoading, fetchError } =
    useFetchList(API_SHOPS);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const inputs = Array.from(document.querySelectorAll(".searchInput input"));
  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        var x;
        for (const { name, value } of inputs) {
          if (value) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
        }
        return items;
      },
    });
  };

  const [IsForm, setIsForm] = useState(false);
  const openInDrawer = async (action, record = null) => {
    setIsSaving(true);
    setSelectedIndex(action === "add" ? -1 : selectedIndex);
    if (record) {
      // GET request using fetch
      const requestOptions = {
        headers: { Authorization: "Bearer " + user.token },
      };

      record = await ApiFetch(
        `${API_SHOPS}/${record.shopId}`,
        requestOptions,
        setNotify
      );
    }

    setSelectedRecord(record ? { ...record } : null);
    setIsForm(action === "add" || action === "edit" ? true : false);
    setContentDrawer(true);
    setIsSaving(false);
  };

  const addOrEdit = async (record, resetForm) => {
    setIsSaving(true);

    if (!record.shopId) {
      // POST request using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };

      const results = await ApiFetch(
        API_SHOPS,
        requestOptions,
        setNotify,
        "Added Successfully!"
      );

      if (results) {
        setRecords([...records, results]);
        setSelectedIndex(records.length);
        resetForm();
      }
    } else {
      // PUT Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      };
      const results = await ApiFetch(
        `${API_SHOPS}/${record.shopId}`,
        requestOptions,
        setNotify,
        "Updated Successfully!"
      );

      if (results) {
        const index = records.findIndex((e) => e.shopId === record.shopId);
        records[index] = results;
      }
    }
    setIsSaving(false);
  };

  const handleDelete = async (id) => {
    setConfirmDialog({ isOpen: false });

    // DELETE Request using fetch
    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SHOPS}/${id}`,
      requestOptions,
      setNotify,
      "Deleted Successfully!"
    );

    if (results === "DELETE") {
      setSelectedIndex(-1);
      setSelectedRecord(null);
      setRecords((records) => records.filter((e) => e.shopId !== id));
    }
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
            <Grid container alignItems="center">
              {columns.map((column) =>
                !column.isAction ? (
                  <Grid item xs={column.width} key={column.field} sx={{ p: 2 }}>
                    {!column.disableSearch && (
                      <InputControl
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearch}
                        className="searchInput"
                        margin="none"
                        fullWidth={false}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={column.width}
                    key={column.field}
                    sx={{ p: 2 }}
                    textAlign="right"
                  >
                    <Tooltip title="Add New" placement="top" arrow>
                      <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => openInDrawer("add")}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                )
              )}
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "69vh" }}>
              <TblContainer>
                {records.length === 0 && <caption>No Record Found!</caption>}
                <TblHead />
                <TableBody>
                  {records &&
                    recordsAfterPagingAndSorting().map((record, index) => (
                      <TableRow
                        key={index}
                        selected={selectedIndex === index}
                        onClick={() => setSelectedIndex(index)}
                        hover
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.title}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.alias}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.country}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {dateStamp(record.createdDate)}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {dateStamp(record.modifiedDate)}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Details" placement="top" arrow>
                            <span>
                              <IconButton
                                aria-label="details"
                                disabled={isSaving}
                                onClick={() => openInDrawer("details", record)}
                              >
                                <ArticleIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top" arrow>
                            <span>
                              <IconButton
                                color="primary"
                                aria-label="edit"
                                disabled={isSaving}
                                onClick={() => openInDrawer("edit", record)}
                              >
                                <EditIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          {user?.roles.includes("Admin") && (
                            <Tooltip title="Delete" placement="top" arrow>
                              <span>
                                <IconButton
                                  color="error"
                                  aria-label="delete"
                                  disabled={isSaving}
                                  onClick={() =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure to delete this record?",
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleDelete(record.shopId);
                                      },
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </TableContainer>
            <TblPagination />
          </Paper>
        </>
      )}

      {/* drawer */}
      <ContentDrawer
        contentDrawer={contentDrawer}
        setContentDrawer={setContentDrawer}
      >
        {IsForm ? (
          <ShopForm
            addOrEdit={addOrEdit}
            selectedRecord={selectedRecord}
            openInDrawer={openInDrawer}
            isSaving={isSaving}
          />
        ) : (
          <ShopDetails
            selectedRecord={selectedRecord}
            openInDrawer={openInDrawer}
          />
        )}
      </ContentDrawer>
    </>
  );
}

export default memo(ShopsList);
